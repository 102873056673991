import { CommonController } from '@/services/request.service'
const getToken = () => CommonController.getQiniuEtcToken()

const params = {
  token: '',
}

async function tokenUpdate() {
  const res = await getToken()
  params.token = res.data.token
}
setTimeout(() => {
  tokenUpdate()

  setInterval(tokenUpdate, 60 * 60 * 1000)
}, 10 * 1000)

const config = {
  placeHolder: '',
  disabled: true,
  toolbarFloat: false,
  toolbar: [
    'title',
    'bold',
    'italic',
    'underline',
    // 'strikethrough',
    'fontScale',
    'color',
    'ol',
    'ul',
    'blockquote',
    'table',
    'link',
    'image',
    // 'hr',
    // 'indent',
    // 'outdent',
    'alignment',
  ],
  pasteImage: true,
  // cleanPaste: true,
  // 允许的标签列表
  allowedTags: [
    'br',
    'span',
    'a',
    'img',
    'b',
    'strong',
    'i',
    'strike',
    'u',
    'font',
    'p',
    'ul',
    'ol',
    'li',
    'blockquote',
    'pre',
    'code',
    'h1',
    'h2',
    'h3',
    'h4',
    'hr',
    'table',
    'thead',
    'tbody',
    'colgroup',
    'col',
    'tr',
    'td',
  ],
  // 允许的标签属性列表
  allowedAttributes: {
    img: ['src', 'alt', 'width', 'height'],
    a: ['href', 'target'],
    font: ['color'],
    code: ['class'],
  },
  // 允许的css样式列表
  allowedStyles: {
    span: ['color', 'font-size', 'line-height', 'font-family'],
    b: ['color'],
    i: ['color'],
    strong: ['color'],
    strike: ['color'],
    u: ['color'],
    p: ['margin-left', 'text-align'],
    h1: ['margin-left', 'text-align'],
    h2: ['margin-left', 'text-align'],
    h3: ['margin-left', 'text-align'],
    h4: ['margin-left', 'text-align'],
    a: [
      'display',
      'border-width',
      'font-family',
      'font-size',
      'line-height',
      'border-style',
      'border-color',
      'text-decoration-line',
    ],
    table: [
      'color',
      'font-family',
      'text-align',
      'text-indent',
      'border',
      'margin',
      'width',
      'border-spacing',
      'border-collapse',
      '',
    ],
    td: [
      'padding',
      'vertical-align',
      'text-align',
      'border-collapse',
      'background-color',
      'word-break',
      'overflow-wrap',
    ],
  },
  upload: {
    url: 'https://upload.qiniup.com/', // 文件上传的接口地址
    params: params, // 键值对,指定文件上传接口的额外参数,上传的时候随文件一起提交
    fileKey: 'file', // 服务器端获取文件数据的参数名
    connectionCount: 3,
    leaveConfirm: '正在上传文件',
  },
}

export default config
